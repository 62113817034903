<template>
    <main class="main-content">
        <!-- Start Breadcrumb -->
        <section class="breadcrumb">
            <ImgBreadcrumb />
            <div class="container">
                <div class="row">
                    <!-- Col -->
                    <div class="col-md-12">
                        <div class="text-bread">
                            <h2>{{ $t('contact') }}</h2>
                            <ul>
                                <li>
                                    <a href="#">{{ $t('home') }}</a>
                                </li>
                                <li>
                                    <span>{{ $t('contact') }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- /Col -->
                </div>
            </div>
        </section>
        <!-- End Breadcrumb -->

        <!-- Start Contact-page -->
        <section class="contact-page body-inner">
            <div class="container">
                <div class="row g-0 align-items-center justify-content-center">
                    <div class="col-xl-4 col-lg-4 col-md-5 col-12">
                        <div class="contact-box">
                            <div class="contact-box-circle">
                                <span>{{ $t('O') }}</span>
                                <span>{{ $t('R') }}</span>
                            </div>
                            <h2 class="contact-box__title">{{ $t('Contact') }} <br> {{ $t('Directly') }}</h2>
                            <div class="contact-box__info-list">
                                <ul>
                                    <li>
                                        <a :href="'tel:'+aboutUs.phone">
                                            <i class="fal fa-phone-alt"></i>
                                            <u>{{ aboutUs.phone }}</u>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i class="fal fa-map-marker-alt"></i>
                                            {{ aboutUs.address }}
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="'mailto:'+aboutUs.email">
                                            <i class="fal fa-globe"></i>
                                            {{ aboutUs.email }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="contact-box__social">
                                <ul>
                                    <li><a :href="aboutUs.instagram"><i class="fab fa-instagram"></i></a></li>
                                    <!-- <li><a :href="aboutUs.facebook"><i class="fab fa-facebook-f"></i></a></li> -->
                                    <li><a :href="aboutUs.linkedIn"><i class="fab fa-linkedin"></i></a></li>
                                    <li><a :href="aboutUs.twitter">
                                        <img loading="lazy" src="@/assets/images/logo-x-twitter.svg" alt="#" />
                                    </a></li>
                                    <!-- <li><a :href="aboutUs.youTube"><i class="fab fa-youtube"></i></a></li> -->

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-7 col-12">
                        <div class="postbox__comment-form contact-wrapper">
                            <h3 class="postbox__comment-form-title">{{ $t('getTouch') }}</h3>
                            <div class="form-contact">

                                <form @submit.prevent="submitForm">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <input type="text" id="name" class="form-control"
                                                    :placeholder="$t('name_input')" v-model="name" required />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <input type="text" id="job" class="form-control" :placeholder="$t('job_input')"
                                                    v-model="job" />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <input type="text" id="company" class="form-control" :placeholder="$t('company_input')"
                                                    v-model="company" />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <input type="text" id="mobile" class="form-control" :placeholder="$t('phone_input')"
                                                    v-model="mobile" required />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <textarea id="message" class="form-control"
                                                    :placeholder="$t('textarea_input')" v-model="message"
                                                    required></textarea>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <button class="btn btn-form" type="submit">
                                                    <span>{{ $t('send_btn') }}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Contact-page -->

        <section class="map-con">
            <div class="map-inner">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3624.687139750642!2d46.71460630000001!3d24.7032794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f0313461aaba1%3A0xcfcbe0bfd701c8f2!2z2LTYsdmD2Kkg2KfZhNmD2YjYryDYp9mE2LDZg9mKINmE2YTYrtiv2YXYp9iqINin2YTYqtis2KfYsdmK2Kk!5e0!3m2!1sar!2ssa!4v1698524234795!5m2!1sar!2ssa"
                    width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </section>
    </main>
</template>

<script>
import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ImgBreadcrumb from "../breadcrumb/index.vue";

const httpLink = new HttpLink({
    uri: 'https://admin.smartcode.com.sa/public/graphql', // Replace with your GraphQL endpoint
});

const apolloClient = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
});

export default {
    components: {
        ImgBreadcrumb
    },
    data() {
        return {
            aboutUs: [],
            name: '',
            job: '',
            company: '',
            mobile: '',
            message: '',
            showSuccessMessage: false,
            showErrorMessage: false,
            successMessage:''
        };
    },
    
    created() {
      this.$apollo
        .query({
          query: gql`
            query GetAboutUs {
                aboutUs {
                    phone
                    email
                    address
                    facebook
                    twitter
                    linkedIn
                    youTube

                }
            }
          `,
        })
        .then(response => {
          
            this.aboutUs = response.data.aboutUs;
        })
        .catch(error => {
          console.error('Error fetching aboutUs:', error);
        });
    },
    computed: {
        isFormValid() {
            return this.name !== '' && this.job !== '' && this.company !== '' && this.mobile !== '' && this.message !== '';
        },
    },
    methods: {
        async submitForm() {
  if (!this.isFormValid) {
    this.showErrorMessage = true;
    return;
  }

  try {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation SendContactUs($input: SendContactUsInput!) {
          sendContactUs(input: $input) {
            status
            message
          }
        }
      `,
      variables: {
        input: {
          name: this.name,
          job: this.job,
          company: this.company,
          mobile: this.mobile,
          message: this.message,
        },
      },
    });

    if (response.data.sendContactUs.status === 'SUCCESS') {
      this.showErrorMessage = false;
      this.name = '';
      this.job = '';
      this.company = '';
      this.mobile = '';
      this.message = '';
      this.showSuccessMessage = true;
      this.successMessage = response.data.sendContactUs.message;

    } else {
      this.showErrorMessage = true;
      console.error('GraphQL Error:', response.data.sendContactUs.message);
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    this.showErrorMessage = true;
  }
},
    },
};
</script>

<style scoped>
.success-message {
    color: green;
}

.error-message {
    color: red;
}
</style>