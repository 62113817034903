<template>
    <!-- Start Partners-h -->
    <section class="partners-h">
        <div class="container">
            <div class="row">
                <!-- Col -->
                <div class="col-md-12">
                    <div class="title title-center">
                        <!-- <h4 class="sub-title">{{ $t('partners') }}</h4> -->
                        <h3>
                            {{ $t('partners') }}
                        </h3>
                    </div>

                    <div class="partners-inner row">
                        <!-- <div ref="swiper" class="swiper partners-slider">
                            <div class="swiper-wrapper"> -->
                                <div class="col-md-3" v-for="partner in partners" :key="partner.id">
                                    <div class="part-block">
                                        <img :src="partner.imageUrl" alt="#" loading="lazy" />
                                    </div>
                                </div>
                            <!-- </div>
                        </div> -->
                            <!-- <div class="swiper-nav">
                                <div class="swiper-button-next partners-next"></div>
                                <div class="swiper-button-prev partners-prev"></div>
                            </div> -->
                            
                            <div class="col-md-12">                             

                                <div class="btn-center">
                                    <router-link to="/partners" class="btn">
                                        <span>{{ $t('more') }}</span>
                                    </router-link>
                                </div>
                            </div>
                    </div>
                </div>
                <!-- /Col -->
            </div>
        </div>
    </section>
    <!-- End Partners-h -->
</template>

<script>
import { ApolloQuery } from 'vue-apollo';
import gql from 'graphql-tag';
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export default {
    components: {
        ApolloQuery,
        Swiper,
    },
    mounted() {
        new Swiper(this.$refs.swiper, {
            loop: true,
            autoplay: {
            delay: 2000,
            },
            speed: 500,
            slidesPerView: 1.6,
            spaceBetween: 10,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination.partners-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next.partners-next',
                prevEl: '.swiper-button-prev.partners-prev',
            },
            scrollbar: {
                el: '.swiper-scrollbar',
            },
            breakpoints: {
                480: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                1180: {
                    slidesPerView: 6,
                    spaceBetween: 10,
                }
            },
        })
    },
    data() {
        return {
            partners: [],
        };
    },
    created() {
        this.$apollo
            .query({
                query: gql`
                    query Getpartners {
                        partners{
                            data{
                                id
                                imageUrl
                            }
                        }
                    }
                `,
            })
            .then(response => {
                this.partners = response.data.partners.data.slice(0, 8)
            })
            .catch(error => {
                console.error('Error fetching partners:', error);
            });
    },
};
</script>