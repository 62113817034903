<template>
  <main class="main-content">
    <!-- Start Breadcrumb -->
    <section class="breadcrumb">
      <ImgBreadcrumb />
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-md-12">
            <div class="text-bread">
              <h2>{{ $t('services') }}</h2>
              <ul>
                <li>
                  <a>{{ $t('home') }}</a>
                </li>
                <li>
                  <span>{{ $t('services') }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
    <!-- End Breadcrumb -->

    <!-- Start About-h -->
    <section class="single-sub about-h about-page body-inner">
      <div class="container">
        <div class="row justify-content-between">

          <!-- Col -->
          <div class="col-md-5">
            <div class="img-about">
              <img :src="getServiceImgByUrl()" alt="">
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-md-6">
            <div class="text-about">
              <div class="title">
                <h3>
                  {{ getServiceTitleByUrl() }}
                </h3>
              </div>
              <div v-html="service.description">
              </div>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
    <!-- End About-h -->

    <section class="sservices-page body-inner">
      <div class="container">
        <div class="row">
          <ItemsSub v-for="sub_service in sub_services" :key="sub_service.id" :image-url="sub_service.imageUrl"
            :title="sub_service.title" :small_description="sub_service.small_description" :slug="sub_service.slug"
            :bannerUrl="sub_service.bannerUrl" :iconUrl="sub_service.iconUrl" />


          <div class="col-md-12">

            <!-- Start Pagination -->
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
                  <a class="page-link" @click="onPageChange(currentPage - 1)" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item" :class="{ 'active': currentPage === page }" v-for="page in totalPages" :key="page">
                  <a class="page-link" @click="onPageChange(page)">{{ page }}</a>
                </li>
                <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
                  <a class="page-link" @click="onPageChange(currentPage + 1)" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { ApolloQuery } from "vue-apollo";
import gql from "graphql-tag";
import Paginate from "vuejs-paginate";
import ItemsSub from "./ItemsSub.vue";
import ImgBreadcrumb from "../breadcrumb/index.vue";

export default {
  name: "SubServices",
  components: {
    ApolloQuery,
    Paginate,
    ItemsSub,
    ImgBreadcrumb
  },
  data() {
    return {
      url: this.$route.params.sub_services,
      sub_services: [],
      services: [],
      currentPage: 1,
      pageSize: 6,
    };
  },
  computed: {
    totalSubServices() {
      return this.sub_services.length;
    },
    totalPages() {
      return Math.ceil(this.totalSubServices / this.pageSize);
    },
    service() {
      return this.services.find(service => service.id === this.url);
    },
  },
  created() {
    const serviceId = parseInt(this.url);
    if (isNaN(serviceId)) {
      console.error("Invalid service ID:", this.url);
    } else {
      console.log("Service ID:", serviceId);
      this.fetchSubServices();
      this.fetchServices();
    }
  },
  methods: {
    getServiceTitleByUrl() {
      if (this.service) {
        return this.service.title;
      } else {
        return "Service not found"; // Or any other default value
      }
    },
    getServicePrghraphByUrl() {
      if (this.service) {
        return this.service.description;
      } else {
        return "Service not found"; // Or any other default value
      }
    },
    getServiceImgByUrl() {
      if (this.service) {
        return this.service.bannerUrl;
      } else {
        return "Service not found"; // Or any other default value
      }
    },
    onPageChange(page) {
      if (page < 1 || page > this.totalPages || page === this.currentPage) {
        return;
      }
      this.currentPage = page;
      this.fetchArticles();
    },
    fetchSubServices() {
      const serviceId = parseInt(this.url);
      if (isNaN(serviceId)) {
        // Handle the case when the service ID is missing or not a valid integer
        console.error("Invalid service ID:", this.url);
        return;
      }

      this.$apollo
        .query({
          query: gql`
            query GetSub_services($service_id: Int!, $page: Int!) {
              sub_services(service_id: $service_id, page: $page) {
                data {
                  id
                  title
                  description
                  small_description
                  position
                  imageUrl
                  bannerUrl
                  iconUrl
                  slug
                }
                
                paginatorInfo {
                  count
                  currentPage
                  firstItem
                  hasMorePages
                  lastItem
                  lastPage
                  perPage
                  total
                }
              }
            }
          `,

          variables: {
            service_id: serviceId,
            page: this.currentPage,
          },
        })

        .then((response) => {
          this.sub_services = response.data.sub_services.data;
        })
        .catch((error) => {
          console.error("Error fetching sub_services:", error);
        });
    },

    fetchServices() {
      this.$apollo
        .query({
          query: gql`
            query GetServices($page: Int!) {
              services(page: $page) {
                data {
                  id
                  title
                  small_description
                  description
                  slug
                  imageUrl
                  bannerUrl
                  iconUrl
                }
              }
              aboutUs {
                background_image
              }
            }
          `,
          variables: {
            page: this.currentPage,
          },
        })
        .then((response) => {
          this.services = response.data.services.data;
          this.aboutUs = response.data.aboutUs;
        })
        .catch((error) => {
          console.error("Error fetching services:", error);
        });
    },
  },

  // watch: {
  //   sub_services: {
  //     immediate: true,
  //     handler(newVal) {
  //       if (newVal) {
  //         // Access the fetched data here: this.sub_services
  //       }
  //     },
  //   },
  // },
};
</script>
