<template>
    <footer>
        <div class="footer-top">
            <div class="container">
                <div class="row">
                    <!-- Col -->
                    <div class="col-md-5">
                        <NewProjects />
                    </div>
                    <!-- /Col -->

                    <!-- Col -->
                    <div class="col-md-3">
                        <div class="footer-inner">
                            <h3>{{ $t('Menu') }}</h3>
                            <ul class="link-f">
                                <li>
                                    <router-link to="/services" class="nav-link" active-class="active" exact href="#">{{
                                        $t('services') }}</router-link>
                                </li>
                                <li>
                                    <router-link to="/Projects" class="nav-link" active-class="active" exact href="#">{{
                                        $t('projects') }}</router-link>
                                </li>
                                <li>
                                    <router-link to="/clients" class="nav-link" active-class="active" exact href="#">{{
                                        $t('clients') }}</router-link>
                                </li>
                                <li>
                                    <router-link to="/partners" class="nav-link" active-class="active" exact href="#">{{
                                        $t('partners') }}</router-link>
                                </li>
                                <!-- <li>
                                    <router-link to="/faq" class="nav-link" active-class="active" exact href="#">{{
                                        $t('faq') }}</router-link>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                    <!-- /Col -->

                    <!-- Col -->
                    <div class="col-md-4">
                        <div class="footer-inner">
                            <h3>{{ $t('office_address') }}</h3>
                            <div class="info-h">
                                <ul>
                                    <li>
                                        <a :href="'mailto:' + aboutUs.email" title="#">
                                            <div class="icon">
                                                <i class="fa fa-envelope"></i>
                                            </div>
                                            <span>
                                                {{ aboutUs.email }}
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="'tel:' + aboutUs.phone" title="#">
                                            <div class="icon">
                                                <i class="fa fa-phone"></i>
                                            </div>
                                            <span>
                                                <u>
                                                    {{ aboutUs.phone }}
                                                </u>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" title="#">
                                            <div class="icon">
                                                <i class="fa fa-map-marker-alt"></i>
                                            </div>
                                            <span>
                                                {{ aboutUs.address }}
                                            </span>
                                        </a>
                                    </li>
                                </ul>

                                <ul class="link-f Socials">
                                    <!-- <li>
                                        <a :href="aboutUs.facebook" target="_blank">
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                    </li> -->
                                    <li>
                                        <a :href="aboutUs.twitter" target="_blank">
                                            <img loading="lazy" src="@/assets/images/logo-x-twitter.svg" alt="#" />
                                            <!-- twitter -->
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="aboutUs.instagram" target="_blank">
                                            <i class="fab fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="aboutUs.linkedIn" target="_blank">
                                            <i class="fab fa-linkedin-in"></i>
                                            <!-- linkedin -->
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- /Col -->
                </div>
            </div>
        </div><!-- /Footer-top -->

        <!-- Footer-bottom -->
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <!-- Col -->
                    <div class="col-md-6">
                        <div class="copy-right">
                            <p>
                                {{ aboutUs.copyrights }}
                            </p>
                        </div>
                    </div>
                    <!-- /Col -->
                    <!-- Col -->
                    <div class="col-md-6">
                        <div class="comany-name">
                            {{ $t('companyKod') }}
                            <a href="https://kodsolutions.net/" target="_blank">
                                <img loading="lazy" src="@/assets/images/kod.png" alt="#" />
                            </a>
                        </div>
                    </div>
                    <!-- /Col -->
                </div>
            </div>
        </div>
        <!-- /Footer-bottom -->
    </footer>
</template>
<script>
import { ApolloQuery } from 'vue-apollo';
import gql from 'graphql-tag';
import NewProjects from "../home/sections/newProjects.vue"

export default {
    components: {
        ApolloQuery,
        NewProjects
    },
    data() {
        return {
            aboutUs: [], // Initialize with an empty array
        };
    },
    created() {
        this.$apollo
            .query({
                query: gql`
          query GetAboutUs {
            aboutUs {
                copyrights
                address
                email
                phone
                whatsApp
                facebook
                twitter
                youTube
                linkedIn
                instagram
                logo
                mobile
                footer
            }
          }
        `,
            })
            .then(response => {
                this.aboutUs = response.data.aboutUs;
            })
            .catch(error => {
                console.error('Error fetching aboutUs:', error);
            });
    },
    // ... other component options
};


</script>