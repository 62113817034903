<template>
    <!-- Start About-more -->
    <section class="about-h about-page prep-h about-more">
            <div class="container">
                <div class="row">
                    
                    <!-- Col -->
                    <div class="col-md-12">
                        <div class="text-why">
                          <div class="title title-center">
                              <!-- <h4 class="sub-title">{{ $t('our_priority') }}</h4> -->
                              <!-- <h3>{{ aboutUs.feature_title }}</h3> -->
                              <h3>{{ $t('our_priority') }}</h3>
                          </div>
                          <!-- <p>
                            {{ aboutUs.feature_description }}
                          </p> -->
                        </div>
                    </div>
                    <!-- /Col -->
                    
                    <!-- Col -->
                    <div class="col-md-3" v-for="priority in priorities.data" :key="priority.id">
                        <div class="block-exp">
                            <div class="icon">
                                <img :src="priority.imageUrl" alt="#" loading="lazy" />
                            </div>
                            <div class="details">
                                <h3>
                                    {{ priority.title }}
                                </h3>
                                <p>
                                    {{ priority.description }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- /Col -->

                </div>
            </div>
        </section>
        <!-- End About-more -->
</template>

<script>
import { ApolloQuery } from 'vue-apollo';
import gql from 'graphql-tag';

export default {
  components: {
    ApolloQuery,
  },
  data() {
    return {
        priorities: [], // Initialize with an empty array
        aboutUs: []
    };
  },
  created() {
    this.$apollo
      .query({
        query: gql`
          query GetAboutUs {
            priorities {
                data {
                    id
                    title
                    description
                    imageUrl
                    position
                }
            }
            aboutUs{
              feature_title
              feature_image_url
              feature_description
            }
          }
        `,
      })
      .then(response => {
        this.priorities = response.data.priorities;
        this.aboutUs = response.data.aboutUs;
      })
      .catch(error => {
        console.error('Error fetching aboutUs:', error);
      });
  },
  // ... other component options
};
</script>