<template>
    <!-- <section class="new-project-h">
        <div class="overlay-img">
            <img :src="aboutUs.contact_image_url" alt="#" />
        </div>
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-md-4">
                    <div class="text-about text-contact">
                        <div class="title">
                            <h3>
                                {{ aboutUs.contact_title }}
                            </h3>
                            <p>
                                {{ aboutUs.contact_description }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-7 col-12"> -->
                    <div class="postbox__comment-form contact-wrapper">
                        <h3 class="postbox__comment-form-title">{{ $t('getTouch') }}</h3>
                        <div class="form-contact">
                          <form @submit.prevent="submitForm">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" id="name" class="form-control"
                                                    :placeholder="$t('name_input')" v-model="name" required />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" id="job" class="form-control" :placeholder="$t('job_input')"
                                                    v-model="job" />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" id="company" class="form-control" :placeholder="$t('company_input')"
                                                    v-model="company" />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <input type="text" id="mobile" class="form-control" :placeholder="$t('phone_input')"
                                                    v-model="mobile" required />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <textarea id="message" class="form-control"
                                                    :placeholder="$t('textarea_input')" v-model="message"
                                                    required></textarea>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <button class="btn btn-form" type="submit">
                                                    <span>{{ $t('send_btn') }}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                        </div>
                    </div>
                <!-- </div>
            </div>
        </div>
    </section>-->
</template> 
  
<script>
import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

const httpLink = new HttpLink({
    uri: 'https://admin.smartcode.com.sa/public/graphql', // Replace with your GraphQL endpoint
});

const apolloClient = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
});

export default {
    data() {
        return {
            name: '',
            job: '',
            company: '',
            mobile: '',
            message: '',
            showSuccessMessage: false,
            showErrorMessage: false,
            successMessage:''

        };
    },
    computed: {
        isFormValid() {
            return this.name !== '' && this.job !== '' && this.company !== '' && this.mobile !== '' && this.message !== '';
        },
    },
    methods: {
        async submitForm() {
  if (!this.isFormValid) {
    this.showErrorMessage = true;
    return;
  }

  try {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation SendContactUs($input: SendContactUsInput!) {
          sendContactUs(input: $input) {
            status
            message
          }
        }
      `,
      variables: {
        input: {
          name: this.name,
          job: this.job,
          company: this.company,
          mobile: this.mobile,
          message: this.message,
        },
      },
    });

    if (response.data.sendContactUs.status === 'SUCCESS') {
      this.showErrorMessage = false;
      this.name = '';
      this.job = '';
      this.company = '';
      this.mobile = '';
      this.message = '';
      this.showSuccessMessage = true;
      this.successMessage= response.data.sendContactUs.message
    } else {
      this.showErrorMessage = true;
      console.error('GraphQL Error:', response.data.sendContactUs.message);
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    this.showErrorMessage = true;
  }
},
    },
};
</script>

<style scoped>
.success-message {
    color: green;
}

.error-message {
    color: red;
}
</style>