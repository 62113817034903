<template>
  <main class="main-content">
    <!-- Start Breadcrumb -->
    <section class="breadcrumb">
      <ImgBreadcrumb />
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-md-12">
            <div class="text-bread">
              <h2>{{ $t('projects') }}</h2>
              <ul>
                <li>
                  <a>{{ $t('home') }}</a>
                </li>
                <li>
                  <span>{{ $t('projects') }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
    <!-- End Breadcrumb -->

    <section class="projects-page body-inner">
      <div class="container">
        <div class="row">
          <div class="col-md-4" v-for="project in displayedProjects" :key="project.id">
            <Item :id="project.id" :title="project.title" :imageUrl="project.imageUrl" :position="project.position"
              :small_description="project.small_description" :slug="project.slug" :images="project.images" />
          </div>
          <div class="col-md-12">

            <!-- Start Pagination -->
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
                  <a class="page-link" @click="onPageChange(currentPage - 1)" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item" :class="{ 'active': currentPage === page }" v-for="page in totalPages" :key="page">
                  <a class="page-link" @click="onPageChange(page)">{{ page }}</a>
                </li>
                <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
                  <a class="page-link" @click="onPageChange(currentPage + 1)" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { ApolloQuery } from "vue-apollo";
import gql from "graphql-tag";
import Paginate from "vuejs-paginate";
import Item from "./Items.vue";
import ImgBreadcrumb from "../breadcrumb/index.vue";

export default {
  components: {
    ApolloQuery,
    Paginate,
    Item,
    ImgBreadcrumb
  },
  data() {
    return {
      projects: [], // Initialize as an empty array
      currentPage: 1,
      pageSize: 9, // Number of Projects per page
    };
  },
  computed: {
    totalProjects() {
      return this.projects.length;
    },
    totalPages() {
      return Math.ceil(this.totalProjects / this.pageSize);
    },
    displayedProjects() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.projects.slice(startIndex, endIndex);
    },
  },
  created() {
    this.fetchProjects();
  },
  methods: {
    onPageChange(page) {
      if (page < 1 || page > this.totalPages || page === this.currentPage) {
        return;
      }
      this.currentPage = page;
      this.fetchArticles();
    },
    fetchProjects() {
      this.$apollo
        .query({
          query: gql`
            query GetProjects($page: Int!) {
              projects(page: $page) {
                paginatorInfo{
                  count
                  currentPage
                  firstItem
                  hasMorePages
                  lastItem
                  lastPage
                  perPage
                  total
                }
                data {
                  id
                  position
                  title
                  description
                  imageUrl
                  images
                  slug
                  phases
                }
              }
            }
          `,
          variables: {
            page: this.currentPage,
          },
        })
        .then((response) => {
          this.projects = response.data.projects.data;
        })
        .catch((error) => {
          console.error("Error fetching Projects:", error);
        });
    },
  },
};
</script>
