<template>
    <div class="jobs-block">
      <div class="all-job">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" :id="'heading' + id">
              <button class="accordion-button" type="button" data-bs-toggle="collapse"
                :data-bs-target="'#collapse' + id" aria-expanded="true"
                :aria-controls="'collapse' + id">
                {{ title }}
              </button>
            </h2>
            <div :id="'collapse' + id" class="accordion-collapse collapse"
              :aria-labelledby="'heading' + id" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>
                  {{ small_description }}
                </p>
                <router-link :to="'/careers/' + id">
                  {{ $t('applyCareer') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
    
<script>
export default {
    props: [
        'id',
        'title',
        'description',
        'small_description',
        'position',
        'skills'
    ],
  mounted() {
    console.log('Service Slug:', this.id);
  },
  methods: {
    // You can remove the 'viewServices' method, as it's no longer needed.
  }
};

</script>
  