import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    home: 'Home',
    about_us: 'about us',
    services: 'services',
    projects: 'Projects',
    clients: 'clients',
    faq: 'FAQ',
    blogs: 'blogs',
    media: 'Media center',
    profile: 'Company profile',
    works: 'how we works',
    contact: 'contact us',
    contact_today: 'Contact Us',
    welcome: 'Welcome',
    who_we_are: 'About Us',
    more: 'See More',
    What_we_can: 'What we can do for you?',
    services_help: 'Our Services',
    read_more: 'read more',
    why_choose: 'why choose us',
    our_projects: 'our projects',
    our_strength: 'OUR OBJECTIVES',
    our_priority: 'Why choose Us',
    priority: 'Portfolio',
    transformation_journey: 'transformation journey',
    value_proposition: 'value proposition',
    company_structures: 'company structures',
    office_address: 'Office Address',
    twitter: 'Twitter',
    title_clients: 'Our Customer',
    send_mesg: 'Send us a Message',
    name_input: 'Name',
    email_input: 'E-mail',
    company_input: 'Company Name',
    job_input: 'Job title',
    textarea_input: 'Enter your message',
    phone_input: 'Phone',
    send_btn: 'Send',
    newProject_title: 'Start A New Project?',
    companyKod: 'Design & Development by',
    testimonial_sub: 'Testimonial',
    Date: 'Date',
    language: 'Language',
    O: 'O',
    R: 'R',
    articles: 'articles',
    Contact: 'Contact',
    Directly: 'Directly',
    Menu: 'Menu',
    Socials: 'Socials',
    career: 'career',
    imagesProjects: 'Project stages',
    partners: 'partners',
    accreditations: 'accreditations',
    achievements: 'Our achievements',
    cv_input: "Link for your CV",
    career_input: 'Select a career',
    apply_req: 'Request to apply for a job',
    timePro: 'Time Project',
    userNamePro: 'Client Name:',
    service:'Category',
    applyCareer:'Apply to career',
    linkedin:'linkedin',
    bio:'your bio',
    getTouch:'GET IN TOUCH',
  },
  ar: {
    home: 'الرئيسية',
    about_us: 'من نحن',
    services: 'الخدمات',
    projects: 'المشاريع',
    clients: 'عملائنا',
    faq: 'الاسئلة الشائعة',
    blogs: 'المدونة',
    media: 'المركز الاعلامي',
    profile: 'بروفايل الشركة',
    works: 'كيف نعمل',
    contact: 'اتصل بنا',
    contact_today: 'اتصل بنا',
    welcome: 'اهلا بك',
    who_we_are: 'من نحن',
    more: 'مشاهدة المزيد',
    What_we_can: 'ما يمكننا القيام به بالنسبة لك؟',
    services_help: 'خدماتنا',
    read_more: 'اقرأ المزيد',
    why_choose: 'لماذا أخترتنا',
    our_projects: 'مشاريعنا',
    our_strength: 'أهدافنا',
    our_priority: 'لماذا تختارنا',
    priority: 'محفظة',
    transformation_journey: 'رحلة التحول',
    value_proposition: 'موقع ذو قيمة',
    company_structures: 'هياكل الشركة',
    office_address: 'عنوان المكتب',
    twitter: 'تويتر',
    title_clients: 'عملائنا',
    send_mesg: 'أرسل لنا رسالة',
    name_input: 'الاسم',
    phone_input: 'الجوال',
    email_input: 'البريد الإلكتروني',
    company_input: 'اسم الشركة',
    job_input: 'اسم الوظيفة',
    textarea_input: 'اكتب رسالتك',
    send_btn: 'إرسال',
    newProject_title: 'بدء مشروع جديد؟',
    companyKod: 'تصميم وبرمجة شركة',
    testimonial_sub: 'اراء العملاء',
    date: 'التاريخ',
    language: 'اللغة',
    O: 'ا',
    R: 'و',
    Contact: 'اتصل',
    Directly: 'مباشر',
    articles: 'المقالات',
    Menu: 'القائمة',
    Socials: 'سوشيال',
    career: 'توظيف',
    imagesProjects: 'مراحل المشروع',
    partners: 'شركائنا',
    accreditations: 'الاعتمادات',
    achievements: 'انجازتنا',
    cv_input: 'رابط السيرة الذاتية',
    career_input: 'اختر مهنة',
    apply_req: 'طلب تقديم وظيفة',
    timePro: 'وقت المشروع',
    userNamePro: 'اسم العميل:',
    service:'القسم',
    applyCareer:'تقدم للوظيفة',
    linkedin:'لينك ان',
    bio:'نبذة عنك',
    getTouch:'ابقى على تواصل',

  },
};

const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "en", // set locale
  fallbackLocale: "eg", // set fallback locale
  messages, // set locale messages
});

export default i18n; 
