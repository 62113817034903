<template>
  <main class="main-content">
    <!-- Start Breadcrumb -->
    <section class="breadcrumb">
      <ImgBreadcrumb />
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-md-12">
            <div class="text-bread">
              <h2>{{ $t('works') }}</h2>
              <ul>
                <li>
                  <a href="#">{{ $t('home') }}</a>
                </li>
                <li>
                  <span>{{ $t('works') }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
    <!-- End Breadcrumb -->

    <!-- Start About-h -->
    <section class="steps-h steps-page body-inner">
      <div class="container">
        <div class="row justify-content-between">
          <!-- Col -->
          <div class="col-md-12">
            <div class="allSteps">
              <!-- Item -->
              <div class="item" v-for="how_we_work in how_we_works" :key="how_we_work.id">
                <div class="step-block">
                  <div class="icon">
                    <img :src="how_we_work.imageUrl" alt="#" loading="lazy">
                  </div>

                  <div class="details">
                    <h4>Step 1</h4>
                    <h3>{{ how_we_work.title }}</h3>
                    <div v-html="how_we_work.description"></div>
                  </div>
                </div>
              </div>
              <!-- /Item -->
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
    <!-- End About-h -->
  </main>
</template>

<script>
import { ApolloQuery } from "vue-apollo";
import gql from "graphql-tag";
import Paginate from "vuejs-paginate";
import ImgBreadcrumb from "../breadcrumb/index.vue";

export default {
  name: "How_we_works",
  props: ['gallery', 'video'],
  components: {
    ApolloQuery,
    Paginate,
    ImgBreadcrumb
  },
  data() {
    return {
      how_we_works: [],
      currentPage: 1,
      pageSize: 4 // Number of How_we_works per page
    };
  },
  computed: {
    totalHow_we_works() {
      return this.how_we_works.length;
    },
    totalPages() {
      return Math.ceil(this.totalHow_we_works / this.pageSize);
    },
    displayedHow_we_works() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.how_we_works.slice(startIndex, endIndex);
    }
  },
  created() {
    this.fetchHow_we_works();
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
      this.fetchHow_we_works();
    },
    fetchHow_we_works() {
      this.$apollo
        .query({
          query: gql`
        query GetHow_we_works($page: Int!) {
          how_we_works(page: $page){
            data{
              id
              title
              description
              position
              counter
              imageUrl
            }
            paginatorInfo {
              count
              currentPage
              firstItem
              hasMorePages
              lastItem
              lastPage
              perPage
              total
            }
          }
        }
      `,
          variables: {
            page: this.currentPage
          }
        })
        .then((response) => {
          this.how_we_works = response.data.how_we_works.data;
        })
        .catch((error) => {
          console.error("Error fetching how_we_works:", error);
        });
    }

  }
};
</script>
