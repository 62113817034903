<template>
    <main class="main-content">
      <!-- Start Breadcrumb -->
      <section class="breadcrumb">
        <ImgBreadcrumb />
        <div class="container">
          <div class="row">
            <!-- Col -->
            <div class="col-md-12">
              <div class="text-bread">
                <h2>{{ $t('partners') }}</h2>
                <ul>
                  <li>
                    <a href="#">{{ $t('home') }}</a>
                  </li>
                  <li>
                    <span>{{ $t('partners') }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Col -->
          </div>
        </div>
      </section>
      <!-- End Breadcrumb -->
      
      <!-- Start partners-page -->
      <section class="partners-page body-inner">
        <div class="container">
          <div class="row">
            <!-- Col -->
            <div class="col-md-3" v-for="client in partners" :key="client.id">
              <div class="part-block">
                <img :src="client.imageUrl" alt="#" loading="lazy" />
              </div>
            </div>
            <!-- /Col -->
          </div>
        </div>
      </section>
      <!-- End partners-page -->
    </main>
  </template>
  
  <script>
  import breadCrumb from "../breadcrumb/index"
  import { ApolloQuery } from 'vue-apollo';
  import gql from 'graphql-tag';
  import ImgBreadcrumb from "../breadcrumb/index.vue";
  
  export default {
    components: {
      breadCrumb,
      ApolloQuery,
      ImgBreadcrumb
    },
    data() {
      return {
        partners: [], // <-- Declare the property here
        aboutUs: [],
      };
    },
    created() {
      this.$apollo
        .query({
          query: gql`
            query GetPartners {
                partners {
                    data {
                        id
                        imageUrl
                        website

                    }
                }
              aboutUs {
                  background_image
              }
            }
          `,
        })
        .then(response => {
            this.partners = response.data.partners.data;
            this.aboutUs = response.data.aboutUs;
        })
        .catch(error => {
          console.error('Error fetching partners:', error);
        });
    },
  };
  </script>