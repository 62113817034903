<template>
  <div  v-if="articleById">
    <!-- Start Breadcrumb -->
    <section class="breadcrumb">
      <ImgBreadcrumb />
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-md-12">
            <div class="text-bread">
              <h2>{{ $t('articles') }}</h2>
              <ul>
                <li>
                  <a href="#">{{ $t('home') }}</a>
                </li>
                <li>
                  <a href="#">{{ $t('articles') }}</a>
                </li>
                <li>
                  <span>{{ articleById.title }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
    <!-- End Breadcrumb -->
    <section class="single-blog single-page body-inner">
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-md-12">
            <div class="single-project-inner">
              <div class="img">
                <img :src="articleById.imageUrl" :alt="articleById.title" />
              </div>

              <div class="row">
                <!-- Col -->
                <div class="col-md-8">
                  <div class="single-inner-pro">
                    <div class="postbox__meta">

                      <span>
                        <a href="#">
                          <i class="fal fa-clock"></i>
                          {{ articleById.date }}
                        </a>
                      </span>
                    </div>
                    <h2>
                      {{ articleById.title }}
                    </h2>
                    <p>
                      {{ articleById.small_description }}
                    </p>
                  </div>
                </div>
                <!-- /Col -->

                <!-- Col -->
                <div class="col-md-4">
                  <div class="title title-center title-sidebar-serv">
                    <h3>Share Now:</h3>
                  </div>
                  <div class="case-social-icon">
                    <a class="facebook" href="#"><i class="fab fa-facebook-f"></i></a>
                    <a class="x-twitter" href="#">
                        <img loading="lazy" src="@/assets/images/logo-x-twitter.svg" alt="#" />
                    </a>
                    <a class="linkedin" href="#"><i class="fab fa-linkedin-in"></i></a>
                  </div>
                </div>
                <!-- /Col -->
              </div>

              <div class="text-single-in">
                <div class="content-inner" v-html="articleById.description"></div>
              </div>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
  </div>
  <div v-else class="col-md-12 loading-message single-page body-inner">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          Loading article details...
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { ApolloQuery } from "vue-apollo";
// import { apolloClient } from "apollo-client";
import gql from "graphql-tag";
import ImgBreadcrumb from "../breadcrumb/index.vue";

export default {
  name: "SingleArticle",
  components: {
    ApolloQuery,
    ImgBreadcrumb
  },
  data() {
    return {
      url: this.$route.params.articleById,
      aboutUs: {},
    }
  },
  apollo: {
    articleById: {
      query: gql`
        query GetArticleById($slug: String!) {
          articleById(slug: $slug) {
            id
            title
            description
            small_description
            date
            position
            imageUrl
            slug
          }
        }
      `,
      variables() {
        return {
          slug: this.url,
        };
      },
    },
    aboutUs: {
      query: gql`
        query GetAboutUs {
          aboutUs {
            background_image
          }
        }
      `,
    },
  },
};
</script>
  