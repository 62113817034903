<template>
  <div v-if="serviceById">

    <!-- Start Breadcrumb -->
    <section class="breadcrumb">
      <ImgBreadcrumb />
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-md-12">
            <div class="text-bread">
              <h2>{{ $t('services') }}</h2>
              <ul>
                <li>
                  <a href="#">{{ $t('home') }}</a>
                </li>
                <li>
                  <a href="#">{{ $t('services') }}</a>
                </li>
                <li>
                  <span>{{ serviceById.title }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
    <!-- End Breadcrumb -->

    <section class="single-blog single-page body-inner">
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-md-12">
            <div class="single-project-inner">
              <div class="img">
                <img :src="serviceById.bannerUrl" :alt="serviceById.title" />
              </div>

              <div class="row">
                <!-- Col -->
                <div class="col-md-8">
                  <div class="single-inner-pro">
                    <!-- <div class="postbox__meta">

                      <span>
                        <a href="#">
                          <i class="fal fa-clock"></i>
                          {{ serviceById.date }}
                        </a>
                      </span>
                    </div> -->
                    <h2>
                      {{ serviceById.title }}
                    </h2>
                    <p>
                      {{ serviceById.small_description }}
                    </p>
                  </div>
                </div>
                <!-- /Col -->

                <!-- Col
                <div class="col-md-4">
                  <div class="title title-center title-sidebar-serv">
                    <h3>Share Now:</h3>
                  </div>
                  <div class="case-social-icon">
                    <a class="behance" href="#"><i class="fab fa-behance"></i></a>
                    <a class="twitter" href="#"><i class="fab fa-twitter"></i></a>
                    <a class="facebook" href="#"><i class="fab fa-facebook-f"></i></a>
                    <a class="pinterest" href="#"><i class="fab fa-pinterest"></i></a>
                  </div>
                </div>
                 /Col -->
              </div>

              <div class="text-single-in">
                <div class="content-inner" v-html="serviceById.description"></div>
              </div>
            </div>
          </div>
          <!-- /Col -->

        </div>
      </div>
    </section>
  </div>

  <div v-else class="loading-message">
    <!-- End Breadcrumb -->
    <div class="error-inner single-page body-inner">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            Loading article details...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { ApolloQuery } from "vue-apollo";
// import { apolloClient } from "apollo-client";
import gql from "graphql-tag";
import ImgBreadcrumb from "../breadcrumb/index";

export default {
  name: "SingleArticle",
  components: {
    ApolloQuery,
    ImgBreadcrumb,
  },
  computed: {
    isProjectLoaded() {
      return !!this.serviceById;
    }
    
  },
  data() {
    return {
      url: this.$route.params.serviceById,
    }
  },
  apollo: {
    serviceById: {
      query: gql`
          query GetServiceById($slug: String!) {
            serviceById(slug: $slug) {
              id
              title
              description
              small_description
              position
              bannerUrl
              imageUrl
              slug
            }
          }
        `,
      variables() {
        // Use vue reactive properties here
        return {
          slug: this.url,
        }
      },
    },
  },

  // watch: {
  //   serviceById: {
  //     immediate: true,
  //     handler(newVal) {
  //       if (newVal) {
  //         // console.log('Article Slug:', this.serviceById.slug);
  //       }
  //     },
  //   },
  // },
};
</script>
  