<template>
  <div  v-if="projectById">
    <!-- Start Breadcrumb -->
    <section class="breadcrumb">
      <ImgBreadcrumb />
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-md-12">
            <div class="text-bread">
              <h2>{{ projectById.title }}</h2>
              <ul>
                <li>
                  <a href="#">{{ $t('home') }}</a>
                </li>
                <li>
                  <a href="#">{{ $t('projects') }}</a>
                </li>
                <li>
                  <span>{{ projectById.title }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
    <!-- End Breadcrumb -->

    <!-- Start Single-project -->
    <section class="single-project single-page body-inner">
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-md-12">
            <div class="single-project-inner">
              <div class="img">
                <img :src="projectById.imageUrl" :alt="projectById.title" />
              </div>
              <div class="row">
                <!-- Col -->
                <div class="col-md-8">
                  <div class="single-inner-pro">
                    
                    <h2>{{ projectById.title }}</h2>
                    <p v-html="projectById.small_description">
                      
                    </p>
                  </div>
                </div>
                <!-- /Col -->

                <!-- Col -->
                <div class="col-md-4">
                  <div class="amaboutinfo__experience">
                    <p><b>{{ $t('timePro') }} :</b> {{ projectById.from_date }} - {{ projectById.to_date }}</p>
                    <p><b>{{ $t('service') }} :</b> {{ projectById.service.title ?? '' }}</p>
                
                    <p><b>{{ $t('userNamePro') }}</b> {{ projectById.client }}</p>
                    <!-- <p><a href="#"><b>Categories:</b> {{ projectById.position }} </a></p> -->
                  </div>
                </div>
                <!-- /Col -->

                <!-- Col -->
                <div class="col-md-12">
                  <div class="text-single-in">
                    <div class="content-des" v-html="projectById.description"></div>
                      <!-- <div class="content-des">
                        <h3>{{ $t('imagesProjects') }}</h3>
                      </div> -->
                      <div class="content-des" v-html="projectById.phases"></div>

                  </div>
                </div>
                <!-- /Col -->
              </div>
            </div>
          </div>
          <!-- /Col -->

    
        </div>
      </div>
    </section>
    <!-- End Single-project -->
  </div>
  

    
  <div class="single-project single-page body-inner loading-message" v-else>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              Loading project details...
            </div>
          </div>
        </div>
    </div>
</template>
  
<script>
import { ApolloQuery } from "vue-apollo";
// import { apolloClient } from "apollo-client";
import gql from "graphql-tag";
import ImgBreadcrumb from "../breadcrumb/index.vue";

export default {
  name: "SingleProject",
  components: {
    ApolloQuery,
    ImgBreadcrumb
  },
  computed: {
    isProjectLoaded() {
      return !!this.projectById;
    }
  },
  data() {
    return {
      url: this.$route.params.projectById,
      aboutUs: {},
    }
  },
  apollo: {
    projectById: {
      query: gql`
          query GetProjectById($slug: String!) {
            projectById(slug: $slug) {
              id
              title
              description
              from_date
              to_date
              small_description
              position
              bannerUrl
              images
              phases
              imageUrl
              client
              slug
              service{
                id
                title
              }
            }
          }
        `,
      variables() {
        // Use vue reactive properties here
        return {
          slug: this.url,
        }
      },
    },
  },  
};
</script>
  