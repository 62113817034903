<template>
  <main class="main-content">
    <!-- Start Breadcrumb -->
    <section class="breadcrumb">
        <ImgBreadcrumb />
        <div class="container">
            <div class="row">
                <!-- Col -->
                <div class="col-md-12">
                    <div class="text-bread">
                        <h2>{{ $t('media') }}</h2>
                        <ul>
                            <li>
                                <a href="#">{{ $t('home') }}</a>
                            </li>
                            <li>
                                <span>{{ $t('media') }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- /Col -->
            </div>
        </div>
    </section>
    <!-- End Breadcrumb -->
  
    <!-- Start Blogs-page -->
    <section class="blogs-page body-inner">
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-md-12">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#pro1" type="button">
                  Articles
                </button>
              </li>
              <li class="nav-item">
                <button class="nav-link" data-bs-toggle="tab" data-bs-target="#pro2" type="button">
                  Gallery
                </button>
              </li>
              <li class="nav-item">
                <button class="nav-link" data-bs-toggle="tab" data-bs-target="#pro3" type="button">
                  Videos
                </button>
              </li>
            </ul>

            <div class="tab-content" id="myTabContent">
              <!-- Tab1 -->
              <div class="tab-pane fade show active" id="pro1">
                <div class="tab-inner tab-blogs row">
                  <!-- Use the Items component to display each article -->
                  <div class="col-md-4" v-for="article in displayedArticles" :key="article.id">
                    <Items :date="article.date" :image-url="article.imageUrl" :title="article.title"
                      :small_description="article.small_description" :slug="article.slug" />
                  </div>
                </div>
              </div>
              <!-- /Tab1 -->

              <!-- Tab2 -->
              <div class="tab-pane fade" id="pro2">
                <div class="tab-inner tab-blogs row">
                  <!-- Col -->
                  <div class="col-md-4" v-for="gallery in galleries" :key="gallery.id">
                    <a :href="gallery.imageUrl" class="gallery-block" data-fancybox>
                      <img :src="gallery.imageUrl" :alt="gallery.title" />
                    </a>
                  </div>
                  <!-- /Col -->
                </div>
              </div>
              <!-- /Tab2 -->

              <!-- Tab2 -->
              <div class="tab-pane fade" id="pro3">
                <div class="tab-inner tab-blogs row">
                  <!-- Col -->
                  <div class="col-md-4" v-for="video in videos" :key="video.id">
                    <a :href="video.youtube" class="gallery-block video-block" data-fancybox>
                      <img :src="video.imageUrl" :alt="video.title" />
                    </a>
                  </div>
                  <!-- /Col -->
                </div>
              </div>
              <!-- /Tab2 -->
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
    <!-- End Blogs-page -->
  </main>
</template>

<script>
import { ApolloQuery } from "vue-apollo";
import gql from "graphql-tag";
import Paginate from "vuejs-paginate";
import ImgBreadcrumb from "../breadcrumb/index.vue"; // Import the Items component
import Items from "./Items.vue"; // Import the Items component

export default {
  name: "Articles",
  props: ['gallery', 'video'],
  components: {
    ApolloQuery,
    Paginate,
    ImgBreadcrumb,
    Items // Register the Items component
  },
  data() {
    return {
      articles: [],
      galleries: [],
      videos: [],
      aboutUs: [],
      currentPage: 1,
      pageSize: 9 // Number of articles per page
    };
  },
  computed: {
    totalArticles() {
      return this.articles.length;
    },
    totalPages() {
      return Math.ceil(this.totalArticles / this.pageSize);
    },
    displayedArticles() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.articles.slice(startIndex, endIndex);
    }
  },
  created() {
    this.fetchArticles();
  },
  methods: {
    onPageChange(page) {
      if (page < 1 || page > this.totalPages || page === this.currentPage) {
        return;
      }
      this.currentPage = page;
      this.fetchArticles();
    },
    fetchArticles() {
      this.$apollo
        .query({
          query: gql`
        query GetArticles($page: Int!) {
          articles(page: $page) {
            paginatorInfo {
                  count
                  currentPage
                  firstItem
                  hasMorePages
                  lastItem
                  lastPage
                  perPage
                  total
                }
            data {
              id
              title
              date
              small_description
              imageUrl
              bannerUrl
              slug
            }
          }
          galleries {
            data {
              id
              title
              imageUrl
            }
          }
          videos {
            data {
              id
              title
              imageUrl
              youtube
            }
          }
          aboutUs {
              background_image
          }
        }
      `,
          variables: {
            page: this.currentPage
          }
        })
        .then((response) => {
          this.articles = response.data.articles.data;
          this.galleries = response.data.galleries.data;
          this.videos = response.data.videos.data;
          this.aboutUs = response.data.aboutUs;
        })
        .catch((error) => {
          console.error("Error fetching articles:", error);
        });
    }

  }
};
</script>

<style>
/* Add your custom CSS styles here */
</style>
