<template>
  <main class="main-content">
    <!-- Start Breadcrumb -->
    <section class="breadcrumb">
      <ImgBreadcrumb />
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-md-12">
            <div class="text-bread">
              <h2>{{ $t('services') }}</h2>
              <ul>
                <li>
                  <a href="#">{{ $t('home') }}</a>
                </li>
                <li>
                  <span>{{ $t('services') }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
    <!-- End Breadcrumb -->

    <!-- Start Services-page -->
    <section class="services-page body-inner">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <Items v-for="service in services" :key="service.id" :image-url="service.imageUrl"
                :icon-url="service.iconUrl" :title="service.title" :small_description="service.small_description"
                :id="service.id" />
            </div>
          </div>

          <!-- Col -->
          <div class="col-md-12">

            <!-- Start Pagination -->
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
                  <a class="page-link" @click="onPageChange(currentPage - 1)" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item" :class="{ 'active': currentPage === page }" v-for="page in totalPages" :key="page">
                  <a class="page-link" @click="onPageChange(page)">{{ page }}</a>
                </li>
                <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
                  <a class="page-link" @click="onPageChange(currentPage + 1)" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
    <!-- End Services-page -->
  </main>
</template>

<script>
import breadCrumb from "../breadcrumb/index";
import { ApolloQuery } from "vue-apollo";
import gql from "graphql-tag";
import Paginate from "vuejs-paginate";
import Items from "./Items.vue";
import ImgBreadcrumb from "../breadcrumb/index.vue";

export default {
  name: "Services",
  components: {
    breadCrumb,
    ApolloQuery,
    Paginate,
    ImgBreadcrumb,
    Items,
  },
  data() {
    return {
      services: [],
      aboutUs: [],
      currentPage: 1,
      pageSize: 9, // Number of services per page
    };
  },
  computed: {
    totalServices() {
      return this.services.length;
    },
    totalPages() {
      return Math.ceil(this.totalServices / this.pageSize);
    },
  },
  created() {
    this.fetchServices();

  },
  methods: {
    onPageChange(page) {
      if (page < 1 || page > this.totalPages || page === this.currentPage) {
        return;
      }
      this.currentPage = page;
      this.fetchArticles();
    },
    fetchServices() {
      this.$apollo
        .query({
          query: gql`
            query GetServices($page: Int!) {
              services(page: $page) {
                data {
                  id
                  title
                  small_description
                  slug
                  imageUrl
                  iconUrl
                }
              }
              aboutUs {
                background_image
              }
            }
          `,
          variables: {
            page: this.currentPage,
          },
          fetchPolicy: 'cache-first',
        })
        .then((response) => {
          this.services = response.data.services.data;
          this.aboutUs = response.data.aboutUs;
        })
        .catch((error) => {
          console.error("Error fetching services:", error);
        });
    },
  },
};
</script>
