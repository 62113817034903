<template>
  <main class="main-content">
    <!-- Start Breadcrumb -->
    <section class="breadcrumb">
      <ImgBreadcrumb />
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-md-12">
            <div class="text-bread">
              <h2>{{ $t('career') }}</h2>
              <ul>
                <li>
                  <a href="#">{{ $t('home') }}</a>
                </li>
                <li>
                  <span>{{ $t('career') }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
    <!-- End Breadcrumb -->
    <section class="contact-h apply-page body-inner">
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-md-7">
            <div class="form-contact contact-wrapper apply-block contact-form">
              <div class="title">
                <h3>{{ $t('apply_req') }}</h3>
              </div>

              <div class="contact-form form-apply">

                <form @submit.prevent="submitForm">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <input type="text" id="name" class="form-control" :placeholder="$t('name_input')" v-model="name"
                          required />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <input type="text" id="Cv" class="form-control" :placeholder="$t('cv_input')" v-model="cv" />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <input type="email" id="email" class="form-control" :placeholder="$t('email_input')" v-model="email" />
                      </div>
                    </div>
                    
                    <div class="col-12">
                      <div class="form-group">
                        <input type="text" id="mobile" class="form-control" :placeholder="$t('phone_input')" v-model="mobile"
                          required />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <input type="text" id="bio" class="form-control" :placeholder="$t('bio')" v-model="bio" />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <select id="career" class="form-control" v-model="career_id" required>
                          <option value="" disabled>{{ $t('career_input') }}</option>
                          <option 
                            v-for="career in careers" :key="career.id" :value="career.id" 
                            :selected="$route.params.id == career.id ? true : false"
                           >{{ career.title }}</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <input type="text" id="linkedin" class="form-control" :placeholder="$t('linkedin')" v-model="linkedin" />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <button class="btn btn-form" type="submit">
                          <span>{{ $t('send_btn') }}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
    <!-- End career-page -->
  </main>
</template>
  
<script>
import breadCrumb from "../breadcrumb/index"
import { ApolloQuery } from 'vue-apollo';
import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ImgBreadcrumb from "../breadcrumb/index.vue";

const httpLink = new HttpLink({
  uri: 'https://admin.smartcode.com.sa/public/graphql', // Replace with your GraphQL endpoint
});

const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export default {
  components: {
    breadCrumb,
    ApolloQuery,
    ImgBreadcrumb
  },
  data() {
    return {
      careers: [], // <-- Declare the property here
      name: '',
      cv: '',
      email: '',
      mobile: '',
      bio: '',
      career_id: '',
      linkedin: '',
      showSuccessMessage: false,
      showErrorMessage: false,
      successMessage:''
    };
  },
  computed: {
    isFormValid() {
      return this.name !== '' && this.cv !== '' && this.email !== '' && this.mobile !== '' && this.bio !== '' && this.career_id !== '' && this.linkedin !== '';
    },
  },
  methods: {
    async submitForm() {
      if (!this.isFormValid) {
        this.showErrorMessage = true;
        return;
      }

      try {
        const response = await apolloClient.mutate({
          mutation: gql`
        mutation sendCareerApply($input: SendCareerApplyInput!) {
          sendCareerApply(input: $input) {
            status
            message
          }
        }
      `,
          variables: {
            input: {
              name: this.name,
              cv: this.cv,
              email: this.email,
              mobile: this.mobile,
              bio: this.bio,
              career_id: parseInt(this.career_id),
              linkedin: this.linkedin,
            },
          },
        });

        if (response.data.sendCareerApply.status === 'SUCCESS') {
          this.showErrorMessage = false;
          this.name = '';
          this.cv = '';
          this.email = '';
          this.mobile = '';
          this.bio = '';
          this.career_id = '';
          this.linkedin = '';
          this.showSuccessMessage = true;
          this.successMessage = response.data.sendContactUs.message;

        } else {
          this.showErrorMessage = true;
          console.error('GraphQL Error:', response.data.sendCareerApply.message);
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        this.showErrorMessage = true;
      }
    },
  },
  created() {
    this.$apollo
      .query({
        query: gql`
            query GetCareers {
              careers {
                data{
                  id
      title
      description
      small_description
      skills
      experience
      position
                }
              }
            }
          `,
      })
      .then(response => {
        this.careers = response.data.careers.data;
      })
      .catch(error => {
        console.error('Error fetching careers:', error);
      });
  },
};
</script>

<style scoped>
.success-message {
    color: green;
}

.error-message {
    color: red;
}
</style>