<template>
  <main class="main-content">
    <!-- Start Breadcrumb -->
    <section class="breadcrumb">
      <ImgBreadcrumb />
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-md-12">
            <div class="text-bread">
              <h2>{{ $t('faq') }}</h2>
              <ul>
                <li>
                  <a href="#">{{ $t('home') }}</a>
                </li>
                <li>
                  <span>{{ $t('faq') }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
    <!-- End Breadcrumb -->

    <section class="faq-page body-inner">
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-md-12">
            <div v-if="questions && questions.length > 0">
              <div class="text-faq" v-for="question in questions" :key="question.id">
                <h3>{{ question.title }}</h3>
                <div class="faq-content" v-html="question.description">
                </div>
              </div>
            </div>
            <div v-else>
              <p>No questions available.</p>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { ApolloQuery } from 'vue-apollo';
import gql from 'graphql-tag';
import ImgBreadcrumb from "../breadcrumb/index.vue";

export default {
  name: "About",
  components: {
    ApolloQuery,
    ImgBreadcrumb
  },
  data() {
    return {
      questions: [],
      aboutUs: [],
    };
  },
  created() {
    this.fetchQuestions();
  },
  methods: {
    fetchQuestions() {
      this.$apollo
        .query({
          query: gql`
            query GetQuestions {
              questions {
                data {
                  id
                  title
                  description
                }
                paginatorInfo {
                  count
                  currentPage
                  firstItem
                  hasMorePages
                  lastItem
                  lastPage
                  perPage
                  total
                }
              }
              aboutUs {
                background_image
              }
            }
          `,
        })
        .then(response => {
          this.questions = response.data.questions.data;
          this.aboutUs = response.data.aboutUs;
        })
        .catch(error => {
          console.error('Error fetching questions:', error);
        });
    },
  },
};
</script>