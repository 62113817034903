<template>
    <header class="sticky" :class="{ active: scrollClass }">
        <div class="container">
            <div class="row">
                <!-- Col -->
                <div class="col-md-12">
                    <div class="head-inner">
                        <div class="logo">
                            <router-link to="/">
                                <img loading="lazy" src="@/assets/logo.png" alt="#" />
                            </router-link>
                        </div>
                        <div class="menu-left">
                            <nav>
                                <ul>
                                    <li>
                                        <router-link to="/" class="nav-link" active-class="active" exact href="#">{{
                                            $t('home') }}</router-link>
                                    </li>
                                    <li class="menu-item-has-children">
                                        <router-link to="/about" class="nav-link" active-class="active" exact href="#">
                                            {{ $t('about_us') }}</router-link>

                                        <ul class="sub-menu">
                                            <li>
                                                <router-link to="/about" active-class="active">
                                                    {{ $t('about_us') }}
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link to="/clients" active-class="active">
                                                    {{ $t('clients') }}
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link to="/partners" active-class="active">
                                                    {{ $t('partners') }}
                                                </router-link>
                                            </li>
                                            <li>
                                                <router-link to="/accreditations" active-class="active">
                                                    {{ $t('accreditations') }}
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <router-link to="/services" class="nav-link" active-class="active" exact href="#">{{
                                            $t('services') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/Projects" class="nav-link" active-class="active" exact href="#">{{
                                            $t('projects') }}</router-link>
                                    </li>
                                    <!-- <li>
                                        <router-link to="/clients" class="nav-link" active-class="active" exact href="#">{{
                                            $t('clients') }}</router-link>
                                    </li> -->
                                    <!-- <li>
                                        <router-link to="/faq" class="nav-link" active-class="active" exact href="#">{{
                                            $t('faq') }}</router-link>
                                    </li> -->
                                    <li>
                                        <router-link to="/articles" class="nav-link" active-class="active" exact href="#">{{
                                            $t('media') }}</router-link>
                                    </li>
                                    <!-- <li>
                                        <router-link to="/how_we_works" class="nav-link" active-class="active" exact
                                            href="#">{{ $t('works') }}</router-link>
                                    </li> -->
                                    <li>
                                        <router-link to="/careers" class="nav-link" active-class="active" exact href="#">{{
                                            $t('career') }}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/contact" class="nav-link" active-class="active" exact href="#">{{
                                            $t('contact') }}</router-link>
                                    </li>
                                </ul>
                            </nav>
                            <!-- 
                            <a href="#" class="lang" title="#">
                                <i class="fa fa-globe"></i>
                                <span></span>
                            </a> -->

                            <div class="lang-h">
                                <button class="btn-lang">
                                    <i class="fa fa-globe"></i>
                                    {{ $t('language') }}
                                </button>
                                <div class="sub-menu">
                                    <!-- <a class="lang" href="#" v-if="lang == 'ar'"> -->
                                    <a class="lang" href="#">
                                        <span @click="changeLocale('en')">English</span>
                                    </a>

                                    <!-- <a class="lang" href="#" v-else> -->
                                    <a class="lang" href="#">
                                        <span @click="changeLocale('ar')" class="arabic">عربي</span>
                                    </a>
                                </div>
                            </div>

                            <ul class="link-f Socials">
                                <!-- <li>
                                    <a :href="aboutUs.facebook" target="_blank">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                </li> -->
                                <li>
                                    <a :href="aboutUs.twitter" target="_blank">
                                        <img loading="lazy" src="@/assets/images/logo-x-twitter.svg" alt="#" />
                                    </a>
                                </li>
                                <li>
                                    <a :href="aboutUs.instagram" target="_blank">
                                        <i class="fab fa-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a :href="aboutUs.linkedin" target="_blank">
                                        <i class="fab fa-linkedin-in"></i>
                                        <!-- linkedin -->
                                    </a>
                                </li>
                            </ul>

                            <div id="openMenu" @click="menuResponsive">
                                <i class="fal fa-bars"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Col -->
            </div>
        </div>

        <!-- Start Sidebar -->
        <div class="overlay-sidebar"></div>
        <div class="sidebar">
            <div class="sidebar-inner">
                <div class="sidear-close-btn">
                    <i class="fal fa-close"></i>
                </div>
                <div class="sidebar-logo">
                    <router-link to="/" class="logo-sidebar" rel="#">
                        <img loading="lazy" src="../../assets/images/logo.png" alt="#" />
                    </router-link>
                </div>
                <div class="sidebar-menu">
                    <ul>
                        <li>
                            <router-link to="/" class="nav-link" active-class="active" exact href="#">
                                {{ $t('home') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/about" class="nav-link" active-class="active" exact href="#">
                                {{ $t('about_us') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/services" class="nav-link" active-class="active" exact href="#">
                                {{ $t('services') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/Projects" class="nav-link" active-class="active" exact href="#">
                                {{ $t('projects') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/clients" class="nav-link" active-class="active" exact href="#">
                                {{ $t('clients') }}
                            </router-link>
                        </li>
                        <!-- <li>
                            <router-link to="/faq" class="nav-link" active-class="active" exact href="#">
                                {{ $t('faq') }}
                            </router-link>
                        </li> -->
                        <li>
                            <router-link to="/articles" class="nav-link" active-class="active" exact href="#">
                                {{ $t('blogs') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/how_we_works" class="nav-link" active-class="active" exact href="#">
                                {{ $t('works') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/contact" class="nav-link" active-class="active" exact href="#">
                                {{ $t('contact') }}
                            </router-link>
                        </li>

                        <li>
                            <div class="lang-h-res">
                                <button class="btn-lang" @click="toggleLang()">
                                    <i class="fa fa-globe"></i>
                                    {{ $t('language') }}
                                </button>
                                <div class="sub-menu">
                                    <!-- <a class="lang" href="#" v-if="lang == 'ar'"> -->
                                    <a class="lang" href="#">
                                        <span @click="changeLocale('en')">English</span>
                                    </a>

                                    <!-- <a class="lang" href="#" v-else> -->
                                    <a class="lang" href="#">
                                        <span @click="changeLocale('ar')" class="arabic">عربي</span>
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- End Sidebar -->
    </header>
</template>


<script>
import gql from 'graphql-tag';
import $ from 'jquery';

export default {
    name: "navbar",
    data() {
        const lang = localStorage.getItem("lang") || "en";
        const authToken = localStorage.getItem("authToken");
        return {
            scrollClass: "",
            programs: [],
            logo: "",
            variant: "dark",
            authToken: authToken,
            lang: lang,
            scTimer: 0,
            scY: 0,
            aboutUs: [], // Initialize with an empty array
        };
    },
    created() {
        this.$apollo
            .query({
                query: gql`
          query GetAboutUs {
            aboutUs {
                copyrights
                address
                email
                phone
                whatsApp
                facebook
                twitter
                youTube
                linkedIn
                instagram
                logo
                mobile
                footer
            }
          }
        `,
            })
            .then(response => {
                this.aboutUs = response.data.aboutUs;
            })
            .catch(error => {
                console.error('Error fetching aboutUs:', error);
            });
    },
    methods: {

        async Programs() {

            await this.$http.get('nav-programs').then(res => {
                this.logo = res.data.data.logo.image;
                this.programs = res.data.data.programs;

            }).catch(error => {
                console.log(error.response.data.message)
            })

        },

        changeLocale(local) {
            localStorage.setItem("lang", local);
            //   console.log(localStorage.lang)
            window.location.reload();
        },

        toggleLang() {
            this.istoggleLang = !this.istoggleLang;
            if (this.istoggleLang) {
                $('.sub-menu').removeClass('close').addClass('open');
            } else {
                $('.sub-menu').removeClass('open').addClass('close');
            }
        },

        menuResponsive() {
            this.isMenuOpen = !this.isMenuOpen;
            if (this.isMenuOpen) {
                $('body').removeClass('close-sidebar').addClass('open-sidebar');
            } else {
                $('body').removeClass('open-sidebar').addClass('close-sidebar');
            }
        },
        closeMenu() {
            this.isMenuOpen = false;
            $('body').removeClass('open-sidebar').addClass('close-sidebar');
        },
        scrollHeader() {
            $(window).on("scroll", () => {
                if ($(window).scrollTop() > 50) {
                this.scrollClass = true; // Add the class
                } else {
                this.scrollClass = false; // Remove the class
                }
            });
        },

        // handleChange(event) {
        //   localStorage.setItem("lang", event.target.value);
        //   window.location.reload();
        // },
    },
    mounted() {
        this.scrollHeader();
        
        $('#openMenu').on('click', this.toggleMenu);
        $('.overlay-sidebar, .sidear-close-btn, .sidebar-menu ul li a').on('click', this.closeMenu);

        switch (this.lang) {
            case "ar": {
                import("@/assets/css/style-ar.css");
                break;
            }
            case "en": {
                import("@/assets/css/style-en.css");
                break;
            }
        }
    },
    beforeDestroy() {
        $('#openMenu').off('click', this.toggleMenu);
        $('.overlay-sidebar, .sidear-close-btn, .sidebar-menu ul li a').off('click', this.closeMenu);
    }
};
</script>