<template>
    <main class="main-content">
        <!-- Start Breadcrumb -->
        <section class="breadcrumb">
            <ImgBreadcrumb />
            <div class="container">
                <div class="row">
                    <!-- Col -->
                    <div class="col-md-12">
                        <div class="text-bread">
                            <h2>{{ $t('about_us') }}</h2>
                            <ul>
                                <li>
                                    <a href="#">{{ $t('home') }}</a>
                                </li>
                                <li>
                                    <span>{{ $t('about_us') }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- /Col -->
                </div>
            </div>
        </section>
        <!-- End Breadcrumb -->

        <!-- Start About-h -->
        <section class="about-h about-page body-inner">
            <div class="container">
                <div class="row justify-content-between align-items-center">

                    <!-- Col -->
                    <div class="col-md-5">
                        <div class="mil-collage-3 mil-appearance">
                            <div class="mil-image-1 mil-appearance">
                                <div class="mil-just-image mil-image-square">
                                    <img :src="aboutUs.about_image_url" :alt="aboutUs.about_title" class="mil-scale-img" data-value-1="1" data-value-2="1.2" style="object-position: top left">
                                </div>
                            </div>
                            <!-- <div class="mil-image-2 mil-appearance">
                                <div class="mil-just-image mil-image-square">
                                    <img :src="aboutUs.about_image_back_url" :alt="aboutUs.about_title">
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <!-- /Col -->

                    <!-- Col -->
                    <div class="col-md-6">
                        <div class="text-about">
                            <div class="title">
                                <h3>
                                    {{ $t('who_we_are')}}
                                </h3>
                            </div>
                            <p v-html="aboutUs.about_description">

                            </p>
                            <a href="#" class="btn" download>
                                <span>{{ $t('profile') }}</span>
                            </a>
                        </div>
                    </div>
                    <!-- /Col -->
                </div>
            </div>
        </section>
        <!-- End About-h -->

        <!-- Start About-h -->
        <section class="about-h about-page about-value body-inner">
            <div class="container">
                <div class="row justify-content-between align-items-center">

                    <!-- Col -->
                    <div class="col-md-12">
                        
                        <div class="block-exp">
                            <div class="title-flex">
                                <div class="icon">
                                    <img :src="aboutUs.vision_image_url" alt="#" loading="lazy" />
                                </div>
                                <h3>
                                    {{ aboutUs.vision_title }}
                                </h3>
                            </div>
                            <p v-html="aboutUs.vision_description"></p>
                        </div>

                        
                    </div>
                    <!-- /Col -->

                    
                </div>
            </div>
        </section>
        <!-- End About-h -->

        <!-- Start About-h -->
        <section class="about-h value-page2 strength-h" >
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-md-12">
                        <div class="title title-center">
                            <h3>
                                {{ aboutUs.value_title }}
                            </h3>
                        </div>
                    </div>

                    <div class="col-md-3" v-for="item in values.data" :key="item.id">
                        <div class="block-exp">
                            <div class="icon">
                                <img :src="item.imageUrl" alt="#" loading="lazy" />
                            </div>
                            <h3>
                                {{ item.title }}
                            </h3>
                            <div class="content-block" v-html="item.description"></div>
                        </div>                        
                    </div>
                    <!-- /Col -->
                </div>
            </div>
        </section>
        <!-- End About-h -->

        <!-- Start About-more -->
        <section class="about-h about-page streng-h about-more"  v-if="priorities.data && priorities.data.length > 0">
            <div class="container">
                <div class="row">
                    <!-- Col -->
                    <div class="col-md-12">
                        <div class="title title-center">
                            <h3>{{ $t('our_priority') }}</h3>
                        </div>
                    </div>

                         <!-- Col -->
                    <div class="col-md-3" v-for="priority in priorities.data" :key="priority.id">
                        <div class="block-exp">
                            <div class="icon">
                                <img :src="priority.imageUrl" alt="#" loading="lazy" />
                            </div>
                            <div class="details">
                                <h3>
                                    {{ priority.title }}
                                </h3>
                                <p>
                                    {{ priority.description }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- /Col -->
                </div>
            </div>
        </section>
        <!-- End About-more -->

        <section class="about-h about-page streng-h about-more">
            <div class="container">
                <div class="row">
                    <!-- Col -->
                    <div class="col-md-12">
                        <div class="title title-center">
                            <h3>{{ $t('our_strength') }}</h3>
                        </div>

                        <div class="tetx-about text-streng">
                            <ul>
                                <li  v-for="item in strength.data" :key="item.id">
                                    <h3>
                                        <div class="num">{{ item.position }}</div>
                                    </h3>
                                    <p>
                                        {{ item.description }}
                                    </p>
                                </li>
                               
                            </ul>
                        </div>
                    </div>
                    <!-- /Col -->
                    <!-- Col 
                    <div class="col-md-3" v-for="priority in priorities.data" :key="priority.id">
                        <div class="block-exp">
                            <div class="icon">
                                <img :src="priority.imageUrl" alt="#" loading="lazy" />
                            </div>
                            <h3>
                                {{ priority.title }}
                            </h3>
                            <p>
                                {{ priority.description }}
                            </p>
                        </div>
                    </div>
                   Col -->

                </div>
            </div>
        </section>

  

        <Partners />
    </main>
</template>

<script>
import { ApolloQuery } from 'vue-apollo';
import gql from 'graphql-tag';
import ImgBreadcrumb from "../breadcrumb/index.vue";
import Partners from "../home/sections/partners.vue";
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export default {
    name: "About",
    components: {
        ApolloQuery,
        ImgBreadcrumb,
        Swiper,
        Partners
    },
    mounted() {
        new Swiper(this.$refs.swiper, {
            // loop: true,
            autoplay: {
            delay: 3000,
            },
            speed: 1000,
            slidesPerView: 1.2,
            spaceBetween: 8,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination.projects-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next.projects-nav',
                prevEl: '.swiper-button-prev.projects-nav',
            },
            scrollbar: {
                el: '.swiper-scrollbar',
            },
            breakpoints: {
                480: {
                    slidesPerView: 1.2,
                    spaceBetween: 0
                },
                767: {
                    slidesPerView: 1.2,
                    spaceBetween: 10
                },
                1180: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                }
            },
        })
    },
    data() {
      return {
        aboutUs: [], 
        strength: [], 
        values: [], 
        priorities: [], 
        portfolios: [], 
        propositions: [], 
      };
    },
    created() {
      this.$apollo
        .query({
          query: gql`
            query GetAboutUs {
                aboutUs {
                    about_title
                    about_image_url
                    about_image_back_url
                    about_description
                    vision_title
                    vision_image_url
                    vision_description
                    mission_title
                    mission_image_url
                    mission_description
                    value_title
                    value_image_url
                    value_description
                    company_structure_image

                }
                strength {
                    data {
                        id
                        title
                        description
                        position
                    }
                }

                values {
                    data {
                        id
                        title
                        description
                        imageUrl
                    }
                }

                priorities {
                    data {
                        id
                        title
                        description
                        imageUrl
                        position
                    }
                }
                
                portfolios{
                    data{
                    id
                    title
                    description
                    position
                    counter
                    }
                }
                propositions{
                    data{
                    id
                    title
                    description
                    position
                    counter
                    }
                }
            }
          `,
        })
        .then(response => {
          
            this.aboutUs = response.data.aboutUs;
            this.strength = response.data.strength;
            this.priorities = response.data.priorities;
            this.portfolios = response.data.portfolios;
            this.propositions = response.data.propositions;
            this.values = response.data.values;
        })
        .catch(error => {
          console.error('Error fetching aboutUs:', error);
        });
    },
    // ... other component options
};
</script>