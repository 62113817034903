<template>
  <main class="main-content">
    <!-- Start Breadcrumb -->
    <section class="breadcrumb">
      <ImgBreadcrumb />
      <div class="container">
        <div class="row">
          <!-- Col -->
          <div class="col-md-12">
            <div class="text-bread">
              <h2>{{ $t('media') }}</h2>
              <ul>
                <li>
                  <a href="#">{{ $t('home') }}</a>
                </li>
                <li>
                  <span>{{ $t('career') }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </section>
    <!-- End Breadcrumb -->
    <section class="contact-h apply-page body-inner">
      <div class="container">
        <div class="row">
          <!-- Col -->

          <div class="col-md-6" v-for="careerItem in careers" :key="careerItem.id">
            <Item 
              :id="careerItem.id"
              :title="careerItem.title"
              :description="careerItem.description"
              :small_description="careerItem.small_description"
              :skills="careerItem.skills"
              :position="careerItem.position"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- End career-page -->
  </main>
</template>
  
<script>
import breadCrumb from "../breadcrumb/index"
import { ApolloQuery } from 'vue-apollo';
import gql from 'graphql-tag';
import ImgBreadcrumb from "../breadcrumb/index.vue";
import Item from "./items.vue";

export default {
  components: {
    breadCrumb,
    ApolloQuery,
    ImgBreadcrumb,
    Item
  },
  data() {
    return {
      careers: [], // <-- Declare the property here
    };
  },
  created() {
    this.$apollo
      .query({
        query: gql`
            query GetCareers {
              careers {
                data{
                  id
                  title
                  description
                  small_description
                  skills
                  experience
                  position
                }
              }
            }
          `,
      })
      .then(response => {
        this.careers = response.data.careers.data;
      })
      .catch(error => {
        console.error('Error fetching careers:', error);
      });
  },
};
</script>