<template>
    <main class="main-content">
        <!-- Start Breadcrumb -->
        <section class="breadcrumb">
            <ImgBreadcrumb />
            <div class="container">
                <div class="row">
                    <!-- Col -->
                    <div class="col-md-12">
                        <div class="text-bread">
                            <h2>{{ $t('accreditations') }}</h2>
                            <ul>
                                <li>
                                    <a href="#">{{ $t('home') }}</a>
                                </li>
                                <li>
                                    <span>{{ $t('accreditations') }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- /Col -->
                </div>
            </div>
        </section>
        <!-- End Breadcrumb -->

        <!-- Start About-h -->
        <section class="about-h accreditations-h strength-h3">
            <div class="container">
                <div class="row justify-content-between">
                        
                    <div class="col-md-3" v-for="accreditation in accreditations.data" :key="accreditation.id">
                        <div class="acc-block">
                            <div class="img">
                                <img :src="accreditation.imageUrl" :alt="accreditation.title">
                            </div>
                            <!-- <div class="details">
                                <h3>
                                    {{ accreditation.title }}
                                </h3>
                                <p>
                                    {{ accreditation.description }}
                                </p>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End About-h -->

    </main>
</template>

<script>
import { ApolloQuery } from 'vue-apollo';
import gql from 'graphql-tag';
import ImgBreadcrumb from "../breadcrumb/index.vue";

export default {
    name: "About",
    components: {
        ApolloQuery,
        ImgBreadcrumb,
    },
    data() {
      return {
        accreditations: []
      };
    },
    created() {
      this.$apollo
        .query({
          query: gql`
            query GetAccreditations {
                accreditations{
                    data{
                        id
                        title
                        imageUrl
                    }
                }
            }
          `,
        })
        .then(response => {
            this.accreditations = response.data.accreditations;
        })
        .catch(error => {
          console.error('Error fetching aboutUs:', error);
        });
    },
    // ... other component options
};
</script>