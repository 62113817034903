
import home from './components/home/index'
import about from './components/about/index'
import accreds from './components/about/accreditations'
import servicesPage from './components/services/index'
import studies from './components/projects/index'
import clients from './components/clients/index'
import partners from './components/partners/index'
import faq from './components/faq/index'
import contact from './components/contact/index'
import how_we_works from './components/how-we-works/index'
import sub_services from './components/services/subServices'
import articles from './components/articles/index'
import singleArticle from './components/articles/singleArticle'
import singleProject from './components/projects/ProjectDetails'
import singleService from './components/services/single-service'
import singleCareer from './components/careers/form'
import careers from './components/careers/index'


const routes = [
    {
        path: "/",
        name: "Home",
        component: home,
        meta: { 
            breadcrumb: 'Home page' ,
            title: "Home page"
        }
    },
    { 
        path: "/about", 
        name: "About",
        component: about,
        meta: { 
            breadcrumb: 'About Us' ,
            title: "About Us"
        }
    },
    { 
        path: "/projects", 
        component: studies,
        name: "Projects",
        meta: { breadcrumb: 'Projects',
        title: "Projects"
     }
    },
    { 
        path: "/projects/:projectById", 
        component: singleProject,
        name: "singleProject",
        meta: { breadcrumb: 'singleProject', title: "Projects" }
    },
    { 
        path: "/clients", 
        component: clients,
        name: "Clients",
        meta: { breadcrumb: 'Clients',title: 'Clients' }
    },
    { 
        path: "/partners", 
        component: partners,
        name: "Partners",
        meta: { breadcrumb: 'Partners', title: 'Partners' }
    },
    { 
        path: "/faq", 
        component: faq,
        name: "Faq",
        meta: { breadcrumb: 'Faq', title: 'Faqs' }
    },
    { 
        path: "/articles", 
        component: articles,
        name: "articles",
        meta: { breadcrumb: 'articles', title: 'Articles' }
    },
    {
        path: '/articles/:articleById',
        name: 'singleArticle',
        component: singleArticle,
        meta: { breadcrumb: 'singleArticle', title: 'Articles' }
    },
    { 
        path: "/how_we_works", 
        component: how_we_works,
        name: "How We Works",
        meta: { breadcrumb: 'How We Works', title: 'How We Works' }
    },
    { 
        path: "/contact", 
        component: contact,
        name: "Contact",
        meta: { breadcrumb: 'Contact', title: 'Contact Us' }
    },
    { 
        path: "/services", 
        component: servicesPage,
        name: "ServicesPage",
        meta: { breadcrumb: 'Services', title: 'Services' }
    },
    {
        path: "/services/:sub_services",
        name: "sub_services",
        component: sub_services,
        meta: { breadcrumb: 'sub_services', title: 'Services' }
    },
    {
        path: "/services/sub_services/:serviceById",
        name: "singleService",
        component: singleService,
        meta: { breadcrumb: 'singleService', title: 'Services' }
    },
    {
        path: "/careers",
        name: "careers",
        component: careers,
        meta: { breadcrumb: 'careers', title: 'Careers' }
    },
    {
        path: "/careers/:id",
        name: "singleCareer",
        component: singleCareer,
        meta: { breadcrumb: 'singleCareer', title: 'Career' }
    },
    { 
        path: "/accreditations", 
        name: "accreditations",
        component: accreds,
        meta: { 
            breadcrumb: 'Sccreditations' ,
            title: "Accreditations"
        }
    },
    // {path: "/users", component: UsersPage, children: [
    //     {path: "", component: UsersHomePage},
    //     {path: ":id", component: UsersViewPage, props: true}
    // ]},
    // // {path: "*", redirect: "/"}, 
    // {path: "*", component: NotFoundPage}, // 404 Error
    // {path: "/store/counterH", component: SmartCounter},
];

// const router = new VueRouter({
//     routes
// });




export default routes;