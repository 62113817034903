<template>
  <keep-alive>
    <div class="overlay-img">
      <img :src="cachedBackgroundImage" alt="#" />
    </div>
  </keep-alive>
</template>

<script>
import { ApolloQuery } from 'vue-apollo';
import gql from 'graphql-tag';

export default {
  components: {
    ApolloQuery,
  },
  data() {
    return {
      aboutUs: {
        background_image: null,
      },
    };
  },
  computed: {
    cachedBackgroundImage() {
      return localStorage.getItem('background_image') || this.aboutUs.background_image;
    },
  },
  created() {
    this.fetchAboutUs();
  },
  methods: {
    fetchAboutUs() {
      this.$apollo
        .query({
          query: gql`
            query GetAboutUs {
              aboutUs {
                background_image
              }
            }
          `,
        })
        .then(response => {
          this.aboutUs = response.data.aboutUs;

          localStorage.setItem('background_image', this.aboutUs.background_image);
        })
        .catch(error => {
          console.error('Error fetching aboutUs:', error);
        });
    },
  },
};
</script>
  
  <style>
  /* Add breadcrumb styles here */
  </style>
  