<template>
    <!-- Start Partners-h -->
    <section class="clients-h">
        <div class="container">
            <div class="row">
                <!-- Col -->
                <div class="col-md-12">
                    <div class="title title-center">
                        <!-- <h4 class="sub-title">{{ $t('clients') }}</h4> -->
                        <h3>
                            {{ $t('title_clients') }}
                        </h3>
                    </div>

                    <div class="clients-inner row">
                        <div class="col-md-3" v-for="client in clients" :key="client.id">
                            <div class="part-block">
                                <img :src="client.imageUrl" alt="#" loading="lazy" />
                            </div>
                        </div>

                        <div class="col-md-12">                            
                            <div class="btn-center">
                                <router-link to="/clients" class="btn">
                                    <span>{{ $t('more') }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Col -->
            </div>
        </div>
    </section>
    <!-- End Partners-h -->
</template>

<script>
import { ApolloQuery } from 'vue-apollo';
import gql from 'graphql-tag';
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export default {
    components: {
        ApolloQuery,
        Swiper,
    },
    mounted() {
        new Swiper(this.$refs.swiper, {
            loop: true,
            autoplay: {
            delay: 2000,
            },
            speed: 500,
            slidesPerView: 1.6,
            spaceBetween: 10,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination.clients-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next.clients-next',
                prevEl: '.swiper-button-prev.clients-prev',
            },
            scrollbar: {
                el: '.swiper-scrollbar',
            },
            breakpoints: {
                480: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                1180: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                }
            },
        })
    },
    data() {
        return {
            clients: [],
        };
    },
    created() {
        this.$apollo
            .query({
                query: gql`
                    query GetClients {
                        clients{
                            data{
                                id
                                imageUrl
                            }
                        }
                    }
                `,
            })
            .then(response => {
                this.clients = response.data.clients.data.slice(0, 8);
            })
            .catch(error => {
                console.error('Error fetching clients:', error);
            });
    },
};
</script>